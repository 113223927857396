import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { Box, Button, CssBaseline, Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { DivWrapper } from "../../Components/Card/Card";
import CustomInput from "../../Components/Input/CustomInput";
import EditorInput from "../../Components/Input/EditorInput";
import CustomSwitch from "../../Components/Switches/CustomSwitch";
import CustomLabel from "../../Components/Label/CustomLabel";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import {
  artifactLoader,
  getCourseSectionList,
  resetArtifact,
  saveCourseSection,
} from "../../redux/action/courseAction";
import SnackbarAlert from "../../Components/Snackbar/SnackbarAlert";
import { COURSE_SUB_SECTION_TITLE_LENGTH, COURSE_SUB_SECTION_DETAILS_LENGTH } from "../../config/textLength";
import ImagePreview from "./components/ImagePreview";
import PdfPreview from "./components/PdfPreview";
import VideoPreview from "./components/VideoPreview";
import QuestionTablePreview from "./components/QuestionTablePreview";
import FormLoader from "../../Components/Loader/formLoader";
import ModalExample, { MainButton } from "../../Components/Modal/CustomModal";
import useSnackbar from "../../Components/Snackbar/useSnackbar";

const useStyles = makeStyles((theme) => ({
  contPrview: {
    marginTop: 5,
    border: "1px solid",
    borderRadius: 5,
    minHeight: "250px",
    width: "100%",
  },

  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingRight: "20px",
  },

  input: {
    width: "100%",
  },
  textDanger: {
    color: "#ff4444",
    marginTop: -24,
    fontSize: 12,
    fontFamily: "Oxanium,cursive",
    marginLeft: 14,
    fontWeight: 400,
  },
  viewLabel: {
    fontSize: 15,
    fontFamily: "Oxanium,cursive",
    fontWeight: "bold",
  },
}));
interface props {
  closeModal?: any;
  togleEvent?: any;
  isModal?: any;
  rowData?: any;
  data?: any;
  sectionLength?: any;
  parentData?: any;
  activeIndex?: any;
  type?: any;
}
const style = {
  position: "absolute" as "absolute",
  top: "70%",
  left: "57%",
  transform: "translate(-50%, -30%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

const SubSectionForm: React.FC<props> = (props) => {
  let { togleEvent, isModal, rowData, data, sectionLength, parentData, activeIndex, type } = props;
  let { courseArtifactList, courseQuestionList, courseAssignment } = data;
  const { id } = useParams();
  const classes = useStyles();
  const Snackbar = useSnackbar();

  const dispatch = useDispatch();
  const { formLoader } = useSelector((state: any) => state.loaderReducer);

  let parentCourseSectionId = parentData?.courseSection?.courseSectionId;
  const initialState: any = {
    courseId: id ? Number(id) : 0,
    courseSectionId: rowData?.courseSectionId ? rowData.courseSectionId : 0,
    sectionTitle: rowData?.sectionTitle ? rowData.sectionTitle : "",
    sectionIndex: rowData?.sectionIndex ? rowData.sectionIndex : 0,
    parentSectionId: rowData?.parentSectionId ? rowData.parentSectionId : parentCourseSectionId || 0,
    sectionDurationHours: rowData?.sectionDurationHours ? rowData.sectionDurationHours : "",
    sectionDurationMinutes: rowData?.sectionDurationMinutes ? rowData.sectionDurationMinutes : "",
    sectionDetails: rowData?.sectionDetails ? rowData.sectionDetails : "",
    allowPublicView: rowData?.allowPublicView ? rowData.allowPublicView : "N",
    assignmentId: rowData?.assignmentId ? rowData.assignmentId : 0,
  };

  const [inputData, setInputData] = useState(initialState);
  let [editorState, setEditorState] = useState<any>(initialState);

  const [open, setOpen] = React.useState(false);
  const [artifactTypeId, setArtifactTypeId] = useState(null);
  const [tdcartifactId, setTdcartifactId] = useState(null);

  const validationSchema = yup.object({
    sectionTitle: yup
      .string()
      .required("Please Enter SubTitle!")
      // .matches(/^[a-zA-Z0-9-_.:,;?'"\s]+$/, "Special Characters are not Allowed")
      .test(
        "len",
        `Characters Length should be less than ${COURSE_SUB_SECTION_TITLE_LENGTH}`,
        (val: any) => val?.toString().length < COURSE_SUB_SECTION_TITLE_LENGTH
      ),
    sectionDetails: yup
      .string()
      .required("Please Enter Description!")
      .test(
        "len",
        `Characters Length should be less than ${COURSE_SUB_SECTION_DETAILS_LENGTH}`,
        (val: any) => val?.toString().length < COURSE_SUB_SECTION_DETAILS_LENGTH
      ),
    sectionDurationHours: yup
      .number()
      .lessThan(23.000000001, `Hours must be less than 24`)
      .typeError("Please Enter Only Numbers!"),

    sectionDurationMinutes: yup
      .number()
      .lessThan(59.000000001, `Minutes must be less than 60`)
      .typeError("Please Enter Only Numbers!"),
  });

  useEffect(() => {
    if (togleEvent !== 0 && isModal) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [togleEvent, isModal]);

  const onSubmit = async () => {
    let data = inputData;
    let uri = `${apiEndPoint.SAVE_COURSE_SECTION}`;
    let resp: any = await dispatch(saveCourseSection(uri, data, ""));
    let { status } = resp;
    if (status) {
      props.closeModal();
      Snackbar.show(rowData?.courseSectionId > 0 ? "Updated Successfully" : "Save Successfully", "success");
      setTimeout(() => {
        let uri = `${apiEndPoint.GET_COURSE_SECTION_LIST}=${id}`;
        dispatch(getCourseSectionList(uri));
      }, 500);
    } else {
      Snackbar.show("Something Went Wrong.", "error");
    }
  };

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: inputData,
    validationSchema,
    onSubmit,
  });

  const handleInput = (field: any, value: any) => {
    setInputData((prval) => {
      return {
        ...prval,
        [field]: value,
      };
    });
  };

  const handlePublicView = (field, event) => {
    let { checked } = event.target;

    setInputData((preval) => {
      return {
        ...preval,
        [field]: checked === true ? "Y" : "N",
      };
    });
  };
  const handleEditorState = (field, value) => {
    if (value === "<p></p>") {
      value = "";
    }
    setEditorState({ ...editorState, ...inputData, [field]: value });
  };

  const handleBlurEditor = (field) => {
    let newEditorState = { ...editorState };
    var html = newEditorState[`${field}`];
    var div = document.createElement("div");
    div.innerHTML = html;
    let newTrimmedValue = div.innerText.trim();
    newEditorState[field] = newTrimmedValue ? newEditorState[`${field}`] : "";
    setInputData({ ...inputData, ...editorState, ...newEditorState });
  };

  const renderChildren = () => {
    let title = "Preview Content";
    return (
      <div>
        <Box sx={style}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ width: "100%" }}>
              <Typography id="transition-modal-title" variant="h6" component="h2">
                {title}
              </Typography>
              <Divider light style={{ marginBottom: 10 }} />
            </div>

            <div>{renderContaint(artifactTypeId, tdcartifactId)}</div>
            <div>
              <Button
                variant="contained"
                onClick={handleCancel}
                style={{
                  fontWeight: "bold",
                  backgroundColor: "#455a64",
                  color: "#fff",
                  marginTop: 20,
                  marginLeft: 20,
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </div>
    );
  };
  const renderContaint = (artifactTypeId, tdcartifactId) => {
    switch (artifactTypeId) {
      case 1:
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CustomLabel style={{ margin: 5 }} label="Video Preview"></CustomLabel>

            <div
              className="contPrview"
              style={{
                marginTop: 10,
                border: "1px solid #d8dbe0",
                borderRadius: 5,
                minHeight: "100px",
                width: "50%",
              }}
            >
              <VideoPreview tdcartifactId={tdcartifactId} />
            </div>
          </div>
        );
      case 2:
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CustomLabel label="Image Container"></CustomLabel>

            <div
            // className="contPrview"
            // style={{
            //   marginTop: 10,
            //   border: "1px solid #d8dbe0",
            //   borderRadius: 5,
            //   minHeight: "100px",
            //   width: "50%",
            // }}
            >
              <ImagePreview
                activeIndex={activeIndex}
                tdcartifactId={tdcartifactId}
                courseSectionId={rowData.courseSectionId}
              />
            </div>
          </div>
        );
      case 3:
        return (
          <>
            <CustomLabel label="PDF Preview"></CustomLabel>

            <div
              className="contPrview"
              style={{
                marginTop: 10,
                border: "1px solid #d8dbe0",
                borderRadius: 5,
                minHeight: "100px",
                width: "100%",
              }}
            >
              <PdfPreview tdcartifactId={tdcartifactId}  courseSectionId={rowData.courseSectionId} />
            </div>
          </>
        );

      default:
        return <></>;
    }
  };

  const handleCancel = () => {
    dispatch(resetArtifact());
    setTimeout(() => {
      setOpen(false);
    }, 100);
  };
  const handlePreview = (e, artifactTypeId, tdcartifactId) => {
    e.preventDefault();
    setArtifactTypeId(artifactTypeId);
    setTdcartifactId(tdcartifactId);
    dispatch(artifactLoader(true));
    setTimeout(() => {
      setOpen(true);
    }, 1500);
  };

  const renderArtifactContent = () => {
    if (courseArtifactList && courseArtifactList.length > 0) {
      let artifactTypeId = courseArtifactList[0]?.artifactTypeId;
      let tdcartifactId = courseArtifactList[0]?.tdcartifactId;
      if (artifactTypeId) {
        return (
          <>
            <MainButton
              onClick={(e) => {
                handlePreview(e, artifactTypeId, tdcartifactId);
              }}
            >
              Preview content
            </MainButton>
          </>
        );
      }
    }
  };

  const renderQuestionContent = () => {
    if (courseQuestionList && courseQuestionList.length > 0) {
      if (courseAssignment.isFinalAssessment === "Y") {
        return (
          <>
            <CustomLabel label="Question List" />
            <QuestionTablePreview questionList={courseQuestionList} />
          </>
        );
      }
      return (
        <>
          <CustomLabel label="Question List" />
          <QuestionTablePreview questionList={courseQuestionList} />
        </>
      );
    }
  };

  const handleBlur = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    let { name } = e.target;
    handleInput(name, val.trim());
  };
  return (
    <>
      <FormLoader visible={formLoader} />

      <DivWrapper>
        <Grid container xs={12} sm={12} md={12} className={classes.root} justify="center">
          <CssBaseline />

          <form
            style={{
              pointerEvents: type === "view" ? "none" : "auto",
              width: "100%",
              background: "#fff",
              padding: "10px",
            }}
          >
            {type === "view" && (
              <>
                <ul>
                  <li>
                    {" "}
                    <label className={classes.viewLabel}>Section Title:</label>{" "}
                    <p dangerouslySetInnerHTML={{ __html: inputData.sectionTitle }} />{" "}
                  </li>
                  <hr />
                  <li>
                    {" "}
                    <label className={classes.viewLabel}>Duration : </label>{" "}
                    <p>
                      {inputData.sectionDurationHours} Hours {inputData.sectionDurationMinutes} Minutes
                    </p>{" "}
                  </li>
                  <hr />
                  <li>
                    {" "}
                    <label className={classes.viewLabel}>Section Description:</label>{" "}
                    <p dangerouslySetInnerHTML={{ __html: inputData.sectionDetails }} />
                  </li>
                </ul>
              </>
            )}
            {type !== "view" && (
              <>
                <CustomSwitch
                  field="allowPublicView"
                  handleStateChange={handlePublicView}
                  checked={inputData.allowPublicView === "Y"}
                  label="Public View"
                />

                <CustomInput
                  variant="outlined"
                  className={classes.input}
                  label="Sub Title"
                  name="sectionTitle"
                  id="subTitle"
                  handleState={(field: any, value: any) => {
                    handleInput(field, value);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                  value={inputData.sectionTitle}
                  error={formik.errors.sectionTitle}
                  helperText={formik.errors.sectionTitle}
                />

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label style={{ marginTop: 20 }}>Duration</label>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <CustomInput
                      variant="outlined"
                      label=" Hours"
                      required={false}
                      name="sectionDurationHours"
                      id="sectionDurationHours"
                      handleState={(field: any, value: any) => {
                        handleInput(field, value);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      value={inputData.sectionDurationHours}
                      error={formik.errors.sectionDurationHours}
                      helperText={formik.errors.sectionDurationHours}
                    />
                    <CustomInput
                      variant="outlined"
                      label=" Minutes"
                      required={false}
                      name="sectionDurationMinutes"
                      id="sectionDurationMinutes"
                      handleState={(field: any, value: any) => {
                        handleInput(field, value);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      value={inputData.sectionDurationMinutes}
                      error={formik.errors.sectionDurationMinutes}
                      helperText={formik.errors.sectionDurationMinutes}
                    />
                  </div>
                </div>

                <>
                  <EditorInput
                    title="Enter Description *"
                    field="sectionDetails"
                    value={inputData.sectionDetails}
                    onBlur={() => handleBlurEditor("sectionDetails")}
                    errorFound={formik.errors.sectionDetails}
                    handleDataChange={handleEditorState}
                  />
                  {formik.errors.sectionDetails ? (
                    <div className={classes.textDanger}>{formik.errors.sectionDetails} </div>
                  ) : null}
                </>
              </>
            )}
          </form>
          {renderArtifactContent()}
          {renderQuestionContent()}
          <ModalExample content={renderChildren()} setOpen={open} />
        </Grid>
      </DivWrapper>
    </>
  );
};

export default SubSectionForm;
