import { reduxConstants } from "../../constant/Redux";

const initialState = {
  // bundleList: [],
  bundleDetails: null,
  getBundleCourseDetails: null,
  detailCourse: null,
  bundleSelectedCourseList: [],
  courseSectionList: null,
  courseData: null,
  selectedPromoCode: null,
  promoCodeAll: [],
  promoCodes: [],
  courses: [],
  bundles: [],
  courseValidity: [],
  courseSelectionList: [],
  bundleSelectionList: [],
  artifactFile: null,
  artifactFiles: [],
  artifactVideos: null,
  artifactPdfs: null,
  allPdfs: null,
  overViewVideos: null,
  saveOverViewVideo: null,
  artiFactFormatAll: null,
  artiFactLanguageAll: null,
  saveCourseSubSectionLanguageVideo: null,
  saveCourseSubSectionLanguagePdf: null,
  saveCourseSubSectionVideo: null,
  sectionTitle: null,
};

export default function courseReducer(state = initialState, action: any) {
  switch (action.type) {
    case reduxConstants.SECTION_TITLE:
      return {
        ...state,
        sectionTitle: action.payload,
      };
    case reduxConstants.COURSE_BY_ID:
      return {
        ...state,
        detailCourse: action.payload,
      };
    case reduxConstants.SAVE_COURSE:
      return {
        ...state,
        courseData: action.payload,
      };
    case reduxConstants.COURSE_CATEGORY_ALL:
      let category = action.payload;
      let courseCategories = [];
      if (category && category.length > 0) {
        courseCategories = category.map((element: any) => {
          return {
            ...element,
            label: element.categoryName,
            value: element.courseCategoryId,
            checked: false,
          };
        });
      }
      return {
        ...state,
        courseCategories,
      };

    case reduxConstants.COURSE_LEVEL_ALL:
      let courseLevels = action.payload;
      if (courseLevels && courseLevels.length > 0) {
        courseLevels = courseLevels.map((element: any) => {
          return {
            ...element,
            label: element.levelName,
            value: element.courseLevelId,
          };
        });
      }
      return {
        ...state,
        courseLevels,
      };

    case reduxConstants.COURSE_VALIDITY_ALL:
      let courseValidity = action.payload;
      if (courseValidity && courseValidity.length > 0) {
        courseValidity = courseValidity.map((element: any) => {
          return {
            ...element,
            label: element.courseValidityName,
            value: element.courseValidityId,
          };
        });
      }
      return {
        ...state,
        courseValidity,
      };
      case reduxConstants.COURSE_COUPON_CODE_ALL:
        let courseCouponCode = action.payload;
        if (courseCouponCode && courseCouponCode.length > 0) {
          courseCouponCode = courseCouponCode.map((element: any) => {
            return {
              ...element,
              label: element.couponCode,
              value: element.courseCouponId,
            };
          });
        }
        return {
          ...state,
          courseCouponCode,
        };
  
    case reduxConstants.COURSE_SECTION_LIST_ALL:
      let courseSectionList = action.payload;
      let newSectionList: any = null;
      if (courseSectionList && courseSectionList.length > 0) {
        newSectionList = courseSectionList.sort(
          (a: any, b: any) => a.courseSection.sectionIndex - b.courseSection.sectionIndex
        );
      }
      let finalAssessmentList: any = [];
      if (newSectionList && newSectionList.length > 0) {
        newSectionList = newSectionList.filter((eachItem: any, i: any) => {
          if (eachItem.courseSection.sectionTitle !== "Final Assessment") {
            return eachItem;
          }
          finalAssessmentList = [...finalAssessmentList, eachItem];
        });
        newSectionList = [...newSectionList, ...finalAssessmentList];

        newSectionList = newSectionList.map((item: any, i: any) => {
          if (item.childCourseSection && item.childCourseSection.length > 0) {
            let childCourseSection = item.childCourseSection;
            childCourseSection.sort((a: any, b: any) => a.courseSection.sectionIndex - b.courseSection.sectionIndex);
            childCourseSection.forEach((eachItem: any, i: any) => {
              if (eachItem.courseQuestionList && eachItem.courseQuestionList.length > 0) {
                let courseQuestionList = eachItem.courseQuestionList.sort(
                  (a: any, b: any) => a.questionIndex - b.questionIndex
                );
                childCourseSection[i] = { ...eachItem, courseQuestionList };
              }
            });
            let lessonAssessmentList: any = [];
            childCourseSection = childCourseSection.filter((eachItem: any, i: any) => {
              if (eachItem.courseSection.sectionTitle !== "Lesson Assessment") {
                return eachItem;
              }
              lessonAssessmentList = [...lessonAssessmentList, eachItem];
            });
            childCourseSection = [...childCourseSection, ...lessonAssessmentList];
            return { ...item, childCourseSection };
          } else {
            return { ...item };
          }
        });
      }
      return {
        ...state,
        courseSectionList: newSectionList,
      };
    case reduxConstants.SAVE_COURSE_SECTION:
      return {
        ...state,
      };
    case reduxConstants.INIT_COURSE:
      return {
        ...state,
        courseSectionList: null,
        detailCourse: null,
        overViewVideos: null,
        artifactFile: null,
        artifactFiles: null,
        artifactVideos: null,
        allPdfs: null,
      };
    case reduxConstants.INIT_COURSE_LIST:
      return {
        ...state,
        currentPage: 0,
      };
    case reduxConstants.INIT_BUNDLE:
      return {
        ...state,
        bundleDetails: null,
      };

    case reduxConstants.INIT_ARTIFACT:
      return {
        ...state,
        overViewVideos: null,
        artifactFile: null,
        artifactFiles: null,
        artifactVideos: null,
        allPdfs:null,
      };

    case reduxConstants.PROMO_CODE_ALL:
      return {
        ...state,
        promoCodes: action.payload,
      };

    case reduxConstants.GET_PROMO_CODE:
      return {
        ...state,
        selectedPromoCode: action.payload,
      };

    case reduxConstants.PROMO_CODE_MAP_ALL:
      return {
        ...state,
        promoCodeAll: action.payload,
      };
    case reduxConstants.GET_ALL_COURSES:
      let courses = action.payload[0].dtoResults;
      let courseSelectionList = [];
      if (courses && courses.length > 0) {
        courseSelectionList = courses.map((element: any) => {
          return {
            ...element,
            fullname: `${element.facultyFirstName} ${element.facultyLastName}`,
            label: element.title,
            value: element.courseId,
            checked: false,
            isPurchased:"N",
            
          };
        });
      }

      return {
        ...state,
        courses: courseSelectionList,
        courseSelectionList,
        currentPage: action.payload[0]?.currentPage,
        totalItems: action.payload[0]?.totalItems,
        totalPages: action.payload[0]?.totalPages,
      };
    case reduxConstants.GET_ALL_BUNDLES:
      let bundles = action.payload;
      let bundleSelectionList = [];
      if (bundles && bundles.length > 0) {
        bundleSelectionList = bundles.map((element: any) => {
          return {
            ...element,
            label: element.bundleName,
            value: element.courseBundleId,
            checked: false,
            isPurchased:"N",
          };
        });
      }
      return {
        ...state,
        bundles,
        bundleSelectionList,
      };
    // case reduxConstants.GET_ALL_BUNDLES:
    //   return {
    //     ...state,
    //     bundleSelectionList: action.payload,
    //   };
    case reduxConstants.SAVE_BUNDLE: {
      let { bundleSelectionList } = state;
      return {
        ...state,
        bundleSelectionList: [...bundleSelectionList, action.payload[0]],
      };
    }
    case reduxConstants.GET_BUNDLE_BY_ID:
      return {
        ...state,
        bundleDetails: action.payload[0],
      };
    case reduxConstants.SAVE_BUNDLE_COURSE_LIST:
      let { bundleSelectedCourseList } = state;
      return {
        ...state,
        bundleSelectedCourseList: [...bundleSelectedCourseList, action.payload[0]],
      };
    case reduxConstants.GET_BUNDLE_COURSE_BY_ID:
      return {
        ...state,
        getBundleCourseDetails: action.payload,
      };

    case reduxConstants.DELETE_COURSE_BY_ID: {
      let { courses }: any = state;
      let newCourseList: any = [];
      courses.forEach((item, i) => {
        if (item.courseId !== action.payload) {
          newCourseList = [...newCourseList, item];
        }
      });
      return {
        ...state,
        courses: newCourseList,
      };
    }
    case reduxConstants.COURSE_SECTION_ARTIFACT:
      return {
        ...state,
        artifactFile: action.payload,
      };
      case reduxConstants.COURSE_SECTION_ARTIFACTS:
        return {
          ...state,
          artifactFiles: action.payload,
        };
    case reduxConstants.COURSE_SECTION_ARTIFACT_BY_PARENT:
      let allVideos = action.payload;
      if (allVideos && allVideos.length > 0) {
        allVideos = allVideos.filter((item) => item.formatType === 1);
      }
      return {
        ...state,
        artifactVideos: allVideos,
      };
      case reduxConstants.COURSE_SECTION_ARTIFACT_BY_PARENT_PDF:
        let allPdfs = action.payload;
        if (allPdfs && allPdfs.length > 0) {
          allPdfs = allPdfs.filter((item) => item.artifactTypeId === 3);
        }
        return {
          ...state,
          artifactPdfs: allPdfs,
        };
    case reduxConstants.GET_COURSE_OVERVIEW_VIDEO:
      let videos = action.payload;
      if (videos && videos.length > 0) {
        videos = videos.filter((item) => item.formatType === 1);
      }
      return {
        ...state,
        overViewVideos: videos,
      };
    case reduxConstants.UPLOAD_COURSE_OVERVIEW_VIDEO:
      return {
        ...state,
        saveOverViewVideo: action.payload,
      };
    case reduxConstants.UPLOAD_COURSE_SUB_SECTION_VIDEO:
      return {
        ...state,
        saveCourseSubSectionVideo: action.payload,
      };

    case reduxConstants.UPLOAD_COURSE_SUB_SECTION_LANGUAGE_VIDEO:
      return {
        ...state,
        saveCourseSubSectionLanguageVideo: action.payload,
      };
      case reduxConstants.UPLOAD_COURSE_SUB_SECTION_LANGUAGE_PDF:
      return {
        ...state,
        saveCourseSubSectionLanguagePdf: action.payload,
      };
    default:
      return state;
  }
}
