import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import { Grid } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { BtnSection, SubmitBtn } from "../../Components/Card/Card";
import CustomInput from "../../Components/Input/CustomInput";
import EditorInput from "../../Components/Input/EditorInput";
import CustomSelect from "../../Components/Select/CustomSelect";
import CustomSwitch from "../../Components/Switches/CustomSwitch";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { enableHeaderTab } from "../../redux/action/configAction";
import {
  getAllCourseOverViewVideo,
  getAllCourses,
  getCourseById,
  getCourseCategoryAll,
  getCourseCouponCodeAll,
  getCourseLevelAll,
  getCourseValidityAll,
  saveCourse,
} from "../../redux/action/courseAction";
import { getFacultyAll } from "../../redux/action/facultyAction";
import {
  COURSE_SHORT_DETAILS_LENGTH,
  COURSE_TITLE_LENGTH,
  COURSE_Pre_Book_Course_Lesson_LENGTH,
  COURSE_COURSE_DETAILS_LENGTH,
  COURSE_WHAT_YOU_LEARN_LENGTH,
  COURSE_WHO_IS_THIS_FOR_LENGTH,
  MERITTO_COURSE_TITLE_LENGTH,
} from "../../config/textLength";
import "./course.css";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";

interface Props {
  courseReducer?: any;
  facultyReducer?: any;
  togleEvent?: any;
  isModal?: any;
  closeModal?: any;
  rowData?: any;
}
const useStyles = makeStyles((theme) => ({
  container: {
    margin: 50,
    marginLeft: 100,
    marginRight: 100,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  input: {
    width: "100%",
  },
  textDanger: {
    color: "#ff4444",
    marginTop: -24,
    fontSize: 12,
    fontFamily: "Oxanium,cursive",
    marginLeft: 14,
    fontWeight: 400,
  },
  textDanger1: {
    color: "#ff4444",
    marginTop: 5,
    fontSize: 12,
    fontFamily: "Oxanium,cursive",
    marginLeft: 14,
    fontWeight: 400,
  },
  cancel: {
    backgroundColor: "red",
    color: "white",
    margin: theme.spacing(2, 2, 2),
  },
  submit: {
    backgroundColor: "blue",
    color: "white",
    margin: theme.spacing(2, 2, 2),
  },
}));
const CourseDetailForm: React.FC<Props> = (props) => {
  let { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isPreBooked, setIsPreBooked] = useState(false);
  let { courseCategories, courseLevels, courseValidity , courseCouponCode} = useSelector((state: Props) => state.courseReducer);
  let { faculties } = useSelector((state: Props) => state.facultyReducer);
  let { detailCourse, currentPage } = useSelector((state: Props) => state.courseReducer);
  let { formLoader } = useSelector((state: any) => state.loaderReducer);
  let parentArtifactId = detailCourse && detailCourse.length > 0 ? detailCourse[0]?.tdcArtifact?.tdcartifactId : null;
  const Snackbar = useSnackbar();

  let initialData: any = {
    courseId: 0,
    courseCouponId: 0,
    coursedetailsId: 0,
    courseCategoryId: "",
    courseLevelId: "",
    ownerBy: "",
    courseValidityTypeId: "",
    defaultVideoId: 0,
    certificateId: 0,
    title: "",
    merittoTitle: "",
    preBookCourseLesson: "",
    shortDetails: "",
    courseDetails: "",
    whoIsThisFor: "",
    whatYouLearn: "",
    careerOpportunities: "",
    hasFeatured: "N",
    isPopularCourse: "N",
    disabled: "N",
    isPreBook:null,
    artifactTypeId: null,
    durationInMinutes: 0,
    name: "",
    path: "",
    tdcartifactId: 0,
    courseValidity: 0,
    price: "",
    courseCardImage:null,
  };
  const [inputData, setInputData] = useState(initialData);
  const [editorState, setEditorState] = useState(initialData);

  useEffect(() => {
    if (props.togleEvent !== 0 && props.isModal) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [props.togleEvent, props.isModal]);

  useEffect(() => {
    if (parentArtifactId) {
      let uri = `${apiEndPoint.GET_COURSE_SECTION_ARTIFACT_BY_PARENT}=${parentArtifactId}`;
      dispatch(getAllCourseOverViewVideo(uri));
    }
  }, [parentArtifactId, dispatch]);

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_COURSE_BY_ID}=${id}`;
      dispatch(getCourseById(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (detailCourse) {
      let { course } = detailCourse[0];
      let {
        courseId,
        courseCouponId,
        courseCategoryId,
        courseLevelId,
        title,
        merittoTitle,
        preBookCourseLesson,
        courseValidityTypeId,
        shortDetails,
        courseDetails,
        whoIsThisFor,
        whatYouLearn,
        careerOpportunities,
        hasFeatured,
        disabled,
        isPreBook,
        ownerBy,
        courseValidity,
        isPopularCourse,
        courseCardImage,
      } = course || {};

      setInputData(
        id
          ? {
              courseId,
              courseCouponId,
              title,
              merittoTitle,
              preBookCourseLesson,
              courseCategoryId,
              courseLevelId,
              courseValidityTypeId,
              shortDetails,
              courseDetails,
              ownerBy,
              whoIsThisFor,
              whatYouLearn,
              careerOpportunities,
              hasFeatured,
              disabled,
              isPreBook,
              isPopularCourse,
              certificateId: detailCourse[0].courseDetails?.certificateId,
              defaultVideoId: detailCourse[0].courseDetails?.defaultVideoId,
              coursedetailsId: detailCourse[0].courseDetails?.coursedetailsId,
              price: detailCourse[0].courseDetails?.price,
              artifactTypeId: detailCourse[0].tdcArtifact?.artifactTypeId,
              durationInMinutes: detailCourse[0].tdcArtifact?.durationInMinutes,
              name: detailCourse[0].tdcArtifact?.name,
              path: detailCourse[0].tdcArtifact?.path,
              tdcartifactId: detailCourse[0].tdcArtifact?.tdcartifactId,
              courseValidity,
              courseCardImage,
            }
          : initialData
      );
    }
  }, [detailCourse, id]);

  useEffect(() => {
    let uri = `${apiEndPoint.GET_COURSE_CATEGORY_ALL}`;
    dispatch(getCourseCategoryAll(uri));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let uri = `${apiEndPoint.GET_COURSE_LEVEL_ALL}`;
    dispatch(getCourseLevelAll(uri));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let uri = `${apiEndPoint.GET_COURSE_VALIDITY}`;
    dispatch(getCourseValidityAll(uri));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let uri = `${apiEndPoint.GET_COURSE_COUPONCODE}`;
    dispatch(getCourseCouponCodeAll(uri));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let uri = `${apiEndPoint.GET_FACULTY_ALL}`;
    let args: any = {
      pageCriteria: {
        pageSize: 200,
        pageNum: 0,
      },
    };
    dispatch(getFacultyAll(uri, args, ""));
    // eslint-disable-next-line
  }, []);

  const validationSchema = yup.object({
    title: yup
      .string()
      .required("Please Enter Title!")
      .strict(false)
      // .matches(/^[a-zA-Z0-9-_.:,;?'"\s]+$/, "Special Characters are not Allowed")
      .trim()
      .test(
        "len",
        `Characters Length should not be greater  than ${COURSE_TITLE_LENGTH}`,
        (val: any) => val?.toString().length <= COURSE_TITLE_LENGTH
      ),
      merittoTitle: yup
      .string()
      .required("Please Enter Meritto Title!")
      .strict(false)
      // .matches(/^[a-zA-Z0-9-_.:,;?'"\s]+$/, "Special Characters are not Allowed")
      .trim()
      .test(
        "len",
        `Characters Length should not be greater  than ${MERITTO_COURSE_TITLE_LENGTH}`,
        (val: any) => val?.toString().length <= MERITTO_COURSE_TITLE_LENGTH
      ),
      // preBookCourseLesson: yup
      // .string()
      // // .required("Please Enter Pre Book Course Lesson!")
      // .strict(false)
      // // .matches(/^[a-zA-Z0-9-_.:,;?'"\s]+$/, "Special Characters are not Allowed")
      // .trim()
      // .test(
      //   "len",
      //   `Characters Length should not be greater  than ${COURSE_Pre_Book_Course_Lesson_LENGTH}`,
      //   (val: any) => val?.toString().length <= COURSE_Pre_Book_Course_Lesson_LENGTH
      // ),
    shortDetails: yup
      .string()
      .required("Please Enter Short Details! ")
      .strict(false)
      .trim()
      .test(
        "len",
        `HTML Content of Short Details should not be greater than ${COURSE_SHORT_DETAILS_LENGTH} characters`,
        (val: any) => val?.toString().length <= COURSE_SHORT_DETAILS_LENGTH
      ),
    courseDetails: yup
      .string()
      .required("Please Enter Course Details!")
      .strict(false)
      .trim()
      .test(
        "len",
        `HTML Content of  Course Details should not be greater than ${COURSE_COURSE_DETAILS_LENGTH} characters`,
        (val: any) => val?.toString().length <= COURSE_COURSE_DETAILS_LENGTH
      ),
    price: yup.number().required("Please Enter Price!"),
    // timerPrice:yup.number().required("Please Enter Timer Price!"),
    whoIsThisFor: yup
      .string()
      .required("Please Enter whoIsThisFor! ")
      .strict(false)
      .trim()
      .test(
        "len",
        `HTML Content of  Details should not be greater than ${COURSE_WHO_IS_THIS_FOR_LENGTH} characters`,
        (val: any) => val?.toString().length <= COURSE_WHO_IS_THIS_FOR_LENGTH
      ),
    whatYouLearn: yup
      .string()
      .required("Please Enter whatYouLearn!")
      .strict(false)
      .trim()
      .test(
        "len",
        `HTML Content of  Details should not be greater than  ${COURSE_WHAT_YOU_LEARN_LENGTH} characters`,
        (val: any) => val?.toString().length <= COURSE_WHAT_YOU_LEARN_LENGTH
      ),
      // careerOpportunities: yup
      // .string()
      // .required("Please Enter careerOpportunities!")
      // .strict(false)
      // .trim()
      // .test(
      //   "len",
      //   `HTML Content of  Details should not be greater than  ${COURSE_WHAT_YOU_LEARN_LENGTH} characters`,
      //   (val: any) => val?.toString().length <= COURSE_WHAT_YOU_LEARN_LENGTH
      // ),
    courseCategoryId: yup.string().required(`Please select CourseCategory!`),
    ownerBy: yup.string().required(`Please select Faculty!`),
    courseValidityTypeId: yup.string().required(`Please select Validity!`),
    courseLevelId: yup.string().required(`Please select Level!`),
  });

  const onSubmit = async () => {
      setIsPreBooked(!isPreBooked)
    let {
      courseId,
      courseCouponId,
      courseCategoryId,
      courseLevelId,
      ownerBy,
      courseValidityTypeId,
      title,
      merittoTitle,
      preBookCourseLesson,
      shortDetails,
      courseDetails,
      whoIsThisFor,
      whatYouLearn,
      careerOpportunities,
      hasFeatured,
      disabled,
      isPreBook,
      price,
      coursedetailsId,
      defaultVideoId,
      certificateId,
      artifactTypeId,
      durationInMinutes,
      name,
      path,
      tdcartifactId,
      isPopularCourse,
      courseCardImage,
    } = inputData;
    if(courseCardImage != null && courseCardImage != ''){
      courseCardImage = courseCardImage.split('/images/courses/')[1];
    }
    let uri = `${apiEndPoint.SAVE_COURSE}`;
    if(isPreBook === null){
      preBookCourseLesson = null;
    }
    let arg = {
      course: {
        courseId,
        courseCouponId : Number(courseCouponId),
        courseCategoryId,
        courseLevelId,
        ownerBy,
        courseValidityTypeId,
        title,
        merittoTitle,
        preBookCourseLesson,
        shortDetails,
        courseDetails,
        whoIsThisFor,
        whatYouLearn,
        careerOpportunities,
        hasFeatured,
        disabled,
        isPreBook,
        isPopularCourse,
        courseCardImage,
      },
      courseDetails: {
        courseId,
        courseCouponId : Number(courseCouponId),
        coursedetailsId,
        defaultVideoId,
        certificateId,
        price: Number(price),
       
      },
      tdcArtifact: {
        artifactTypeId: artifactTypeId ? artifactTypeId : null,
        durationInMinutes: durationInMinutes ? durationInMinutes : 0,
        name: name ? name : "",
        path: path ? path : "",
        tdcartifactId: tdcartifactId ? tdcartifactId : 0,
      },
    };
    let response: any = await dispatch(saveCourse(uri, arg));

    let { status } = response;
    if (status === "success") {
      if (id) {
        history.push(`/home/course`);
      } else {
        props.closeModal(false);
      }
      Snackbar.show(id ? "Updated Successfully" : "Save Successfully", "success");
      setTimeout(() => {
        if (!id) {
          if (currentPage == 0) {
            fetchCourseList("");
          }
        }
        dispatch(enableHeaderTab(true));
      }, 500);
    } else {
      Snackbar.show(status, "error");
    }
  };
  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: inputData,
    validationSchema,
    onSubmit,
  });

  const fetchCourseList = (searchText) => {
    let uri = `${apiEndPoint.GET_ALL_COURSES}`;
    let args: any = {
      pageCriteria: {
        pageSize: 100,
        pageNum: 0,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "title",
            fieldOperator: "LIKE",
            fieldData: [`${searchText}%`],
          },
        ],
      };
    }
    dispatch(getAllCourses(uri, args, ""));
  };
  const handleInput = (name: any, value: any) => {
    setInputData((prval) => {
      return {
        ...prval,
        [name]: value,
      };
    });
  };
  const handleEditorState = (field, value) => {
    if (value === "<p></p>") {
      value = "";
    }
    setEditorState({ ...editorState, ...inputData, [field]: value });
  };

  const handleBlurEditor = (field) => {
    let newEditorState = { ...editorState };
    var html = newEditorState[`${field}`];
    var div = document.createElement("div");
    div.innerHTML = html;
    let newTrimmedValue = div.innerText.trim();
    newEditorState[field] = newTrimmedValue ? newEditorState[`${field}`] : "";
    setInputData({ ...inputData, ...editorState, ...newEditorState });
  };
  const handleSwitch = (field, event) => {
    let { checked } = event.target;
    setInputData((preval) => {
      return {
        ...preval,
        [field]: checked === true ? "Y" : field!='isPreBook'?"N":null,
      };
    });
  };

  const handleSwitchs = (field, event) => {
    let { checked } = event.target;
     setIsPreBooked(!isPreBooked)
    setInputData((preval) => {
      return {
        ...preval,
        [field]: checked === true ? "Y" : field!='isPreBook'?"N":null,
      };
    });
  };
  const handleBlur = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    let { name } = e.target;
    handleInput(name, val.trim());
  };

  return (
    <div>
      <CssBaseline />
      {formLoader && <FormLoader visible={formLoader} />}
      <form className="container">
        <div
          style={{
            display: "grid",
            gap: 20,
            marginTop: 20,
            gridTemplateColumns: "repeat(3, 1fr)",
            gridTemplateRows: "auto",
          }}
          className="cmn-grd"
        >
          <CustomSwitch
            field="disabled"
            handleStateChange={handleSwitch}
            checked={inputData.disabled === "Y"}
            label="Disabled"
          />

          <CustomSwitch
            field="hasFeatured"
            handleStateChange={handleSwitch}
            checked={inputData.hasFeatured === "Y"}
            label="Featured"
          />
          <CustomSwitch
            field="isPopularCourse"
            handleStateChange={handleSwitch}
            checked={inputData.isPopularCourse === "Y"}
            label="Popular Course"
          />
          <CustomSwitch
            field="isPreBook"
            handleStateChange={handleSwitchs}
            checked={inputData.isPreBook === "Y"}
            label="Pre Book"
          />
        </div>
        <CustomInput
          variant="outlined"
          label="Course Title"
          name="title"
          id="title"
          className={classes.input}
          value={inputData.title}
          handleState={(field: any, value: any) => {
            handleInput(field, value);
          }}
          onBlur={(e) => {
            handleBlur(e);
          }}
          error={formik.errors.title}
          helperText={formik.errors.title}
          autoFocus
        />
          <CustomInput
          variant="outlined"
          label="Meritto Course Title"
          name="merittoTitle"
          id="merittoTitle"
          className={classes.input}
          value={inputData.merittoTitle}
          handleState={(field: any, value: any) => {
            handleInput(field, value);
          }}
          onBlur={(e) => {
            handleBlur(e);
          }}
          error={formik.errors.merittoTitle}
          helperText={formik.errors.merittoTitle}
          autoFocus
        />
        <CustomInput
          variant="outlined"
          label="Price"
          name="price"
          id="price"
          value={inputData.price}
          className={classes.input}
          style={{ marginTop: 30 }}
          handleState={(field: any, value: any) => {
            handleInput(field, value);
          }}
          onBlur={(e) => {
            handleBlur(e);
          }}
          error={formik.errors.price}
          helperText={formik.errors.price}
          autoFocus
        />
         <CustomInput
          variant="outlined"
          label="course CouponId"
          name="courseCouponId"
          id="courseCouponId"
          value={inputData.courseCouponId}
          className={classes.input}
          style={{ marginTop: 30 }}
          handleState={(field: any, value: any) => {
            handleInput(field, value);
          }}
          onBlur={(e) => {
            handleBlur(e);
          }}
          error={formik.errors.courseCouponId}
          helperText={formik.errors.courseCouponId}
          autoFocus
        />
      
        <div
          style={{
            display: "grid",
            marginTop: 30,
            gap: 20,
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: "auto",
          }}
          className="cmn-grd"
        >
          <>
            <CustomSelect
              field="courseCategoryId"
              selectedValue={inputData.courseCategoryId}
              label="courseCategory"
              data={courseCategories}
              handleStateChange={handleInput}
              errors={
                formik.errors.courseCategoryId ? (
                  <div className={classes.textDanger1}>{formik.errors.courseCategoryId}</div>
                ) : null
              }
            />
          </>
          <CustomSelect
            field="ownerBy"
            selectedValue={inputData.ownerBy}
            label="Faculty"
            data={faculties}
            handleStateChange={handleInput}
            errors={formik.errors.ownerBy ? <div className={classes.textDanger1}>{formik.errors.ownerBy}</div> : null}
          />
        </div>
        <div
          style={{
            display: "grid",
            marginTop: 40,
            gap: 20,
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: "auto",
          }}
          className="cmn-grd"
        >
          <CustomSelect
            field="courseValidityTypeId"
            selectedValue={inputData.courseValidityTypeId}
            label="Validity"
            data={courseValidity}
            handleStateChange={handleInput}
            errors={
              formik.errors.courseValidityTypeId ? (
                <div className={classes.textDanger1}>{formik.errors.courseValidityTypeId}</div>
              ) : null
            }
          />
          <CustomSelect
            field="courseLevelId"
            selectedValue={inputData.courseLevelId}
            label="Level"
            data={courseLevels}
            handleStateChange={handleInput}
            // errorFound={formik.errors.Level}
            errors={
              formik.errors.courseLevelId ? (
                <div className={classes.textDanger1}>{formik.errors.courseLevelId}</div>
              ) : null
            }
          />
          {/* {formik.errors.Level ? <div className={classes.textDanger1}>{formik.errors.Level}</div> : null} */}
        </div>

        <>
          <EditorInput
            title="Enter Short Details *"
            field="shortDetails"
            value={inputData.shortDetails}
            onBlur={() => handleBlurEditor("shortDetails")}
            errorFound={formik.errors.shortDetails}
            handleDataChange={handleEditorState}
          />
          {formik.errors.shortDetails ? <div className={classes.textDanger}>{formik.errors.shortDetails}</div> : null}
        </>
        <>
          <EditorInput
            title="Enter Course Details *"
            field="courseDetails"
            value={inputData.courseDetails}
            onBlur={() => handleBlurEditor("courseDetails")}
            errorFound={formik.errors.courseDetails}
            handleDataChange={handleEditorState}
          />
          {formik.errors.courseDetails ? <div className={classes.textDanger}>{formik.errors.courseDetails}</div> : null}
        </>

        <>
          <EditorInput
            title="Who Is This For *"
            field="whoIsThisFor"
            value={inputData.whoIsThisFor}
            onBlur={() => handleBlurEditor("whoIsThisFor")}
            errorFound={formik.errors.whoIsThisFor}
            handleDataChange={handleEditorState}
          />
          {formik.errors.whoIsThisFor ? <div className={classes.textDanger}>{formik.errors.whoIsThisFor}</div> : null}
        </>
        <>
          <EditorInput
            title="What you learn *"
            field="whatYouLearn"
            value={inputData.whatYouLearn}
            onBlur={() => handleBlurEditor("whatYouLearn")}
            errorFound={formik.errors.whatYouLearn}
            handleDataChange={handleEditorState}
          />
          {formik.errors.whatYouLearn ? <div className={classes.textDanger}>{formik.errors.whatYouLearn}</div> : null}
        </>
        <>
          <EditorInput
            title="Career Opportunities *"
            field="careerOpportunities"
            value={inputData.careerOpportunities}
            onBlur={() => handleBlurEditor("careerOpportunities")}
            errorFound={formik.errors.careerOpportunities}
            handleDataChange={handleEditorState}
          />
          {formik.errors.careerOpportunities ? <div className={classes.textDanger}>{formik.errors.careerOpportunities}</div> : null}
        </>
        <>
        {isPreBooked ? (<CustomInput
          variant="outlined"
          label="Pre Book Course Lesson"
          name="preBookCourseLesson"
          id="preBookCourseLesson"
          className={classes.input}
          value={inputData.preBookCourseLesson}
          handleState={(field: any, value: any) => {
            handleInput(field, value);
          }}
          onBlur={(e) => {
            handleBlur(e);
          }}
          // error={formik.errors.PreBookCourseLesson}
          // helperText={formik.errors.PreBookCourseLesson}
          autoFocus
        />)
        :null}
        
        </>
        {id ? (
          <Grid>
            <BtnSection>
              <SubmitBtn disabled={formLoader} disabledColor={formLoader} onClick={formik.handleSubmit}>
                Update
              </SubmitBtn>
            </BtnSection>
          </Grid>
        ) : null}
      </form>
    </div>
  );
};

export default CourseDetailForm;
