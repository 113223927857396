import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { BtnSection, SubmitBtn } from "../../Components/Card/Card";
import * as yup from "yup";
import { enableHeaderTab } from "../../redux/action/configAction";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import CustomInput from "../../Components/Input/CustomInput";
import EditorInput from "../../Components/Input/EditorInput";
import { getAllBundlesByCustomFilter, getbBundleById, saveBundle } from "../../redux/action/courseAction";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";
import { COURSE_BUNDLE_BUNDLE_NAME_LENGTH, COURSE_BUNDLE_DETAILS_LENGTH } from "../../config/textLength";
import CustomSwitch from "../../Components/Switches/CustomSwitch";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textDanger: {
    color: "#ff4444",
    marginTop: -24,
    fontSize: 12,
    fontFamily: "Oxanium,cursive",
    marginLeft: 14,
    fontWeight: 400,
  },
  input: {
    marginTop: 30,
  },
  form: {
    width: "100%",
  },
  fdataimg: {
    paddingTop: "40px",
    paddingBottom: "15px",
  },
  profile: {
    padding: theme.spacing(50, 14, 50, 14),
  },
  cancel: {
    backgroundColor: "red",
    color: "white",
    margin: theme.spacing(2, 2, 2),
  },
  submit: {
    backgroundColor: "blue",
    color: "white",
    margin: theme.spacing(2, 2, 2),
  },
  disable: {
    marginBottom: 10,
  },
}));

interface props {
  url?: any;
  courseReducer?: any;
  togleEvent?: any;
  isModal?: any;
  closeModal?: any;
}
const initialState: any = {
  bundleName: "",
  bundlePrice: "",
  actualPrice: "",
  details: "",
  maxBundleSize: "",
  bundleImagePath: "",
  whatYouLearnFromBundle: "",
  disabled: "N",
};
const BundleForm: React.FC<props> = (props) => {
  const Snackbar = useSnackbar();

  const dispatch = useDispatch();
  const classes = useStyles();
  let { id } = useParams();
  const history = useHistory();
  let [inputData, setInputData] = useState<any>(initialState);

  let [editorState, setEditorState] = useState<any>(initialState);
  let { bundleDetails } = useSelector((state: props) => state.courseReducer);
  let { formLoader } = useSelector((state: any) => state.loaderReducer);

  const validationSchema = yup.object({
    bundleName: yup
      .string()
      .strict(false)
      // .matches(/^[a-zA-Z0-9-_.:,;?'"\s]+$/, "Special Characters are not Allowed")
      .trim()
      .required("Please Enter Bundle Name!")
      .test(
        "len",
        `Characters Length should not be greater than ${COURSE_BUNDLE_BUNDLE_NAME_LENGTH}`,
        (val: any) => val?.toString().length <= COURSE_BUNDLE_BUNDLE_NAME_LENGTH
      ),

    maxBundleSize: yup.string().strict(false).trim().required("Please Enter Bundle Size!"),

    bundlePrice: yup.string().strict(false).trim().required("Please Enter Bundle Price!"),
    actualPrice: yup.string().strict(false).trim().required("Please Enter Actual Price!"),

    details: yup
      .string()
      .required("Please Enter Details!")
      .test(
        "len",
        `HTML Content of Details should not be greater than ${COURSE_BUNDLE_DETAILS_LENGTH}} characters`,
        (val: any) => val?.toString().length <= COURSE_BUNDLE_DETAILS_LENGTH
      ),
      whatYouLearnFromBundle: yup
      .string()
      .required("Please Enter whatYouLearn!")
      .test(
        "len",
        `HTML Content of whatYouLearn should not be greater than ${COURSE_BUNDLE_DETAILS_LENGTH}} characters`,
        (val: any) => val?.toString().length <= COURSE_BUNDLE_DETAILS_LENGTH
      ),
  });
  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_BUNDLE_BY_ID}?courseBundleId=${id}`;
      dispatch(getbBundleById(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && bundleDetails) {
      let { bundleName, maxBundleSize, bundlePrice, details, actualPrice, bundleImagePath, whatYouLearnFromBundle , disabled, } = bundleDetails;

      setInputData({
        courseBundleId: id ? id : 0,
        disabled,
        bundleName,
        maxBundleSize,
        bundlePrice,
        actualPrice,
        details,
        bundleImagePath,
        whatYouLearnFromBundle,
      });
    }
  }, [id, bundleDetails]);

  const onSubmit = async () => {
    let { bundleName, maxBundleSize, bundlePrice, details, actualPrice, bundleImagePath, whatYouLearnFromBundle , disabled} = inputData;
    if(bundleImagePath != null && bundleImagePath != ''){
      bundleImagePath = bundleImagePath.split('/images/bundles/')[1];
    }
    const addBundleData = {
      courseBundleId: id ? Number(id) : 0,
      bundleName,
      maxBundleSize,
      bundlePrice,
      actualPrice,
      details,
      bundleImagePath,
      whatYouLearnFromBundle,
      disabled,
    };
    let uri = `${apiEndPoint.SAVE_BUNDLE}`;
    let response: any = await dispatch(saveBundle(uri, addBundleData));
    let { status } = response;
    if (status) {
      if (id) {
        history.push({ pathname: `/home/bundle` });
      } else {
        props.closeModal(false);
      }
      Snackbar.show(id ? "Updated Successfully" : "Save Successfully", "success");
      setTimeout(() => {
        if (!id) {
          fetchBundleList();
        }
        dispatch(enableHeaderTab(true));
      }, 500);
    } else {
      Snackbar.show("Something Went Wrong.", "error");
    }
  };

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: inputData,
    validationSchema,
    onSubmit,
  });
  const fetchBundleList = () => {
    let uri = `${apiEndPoint.GET_ALL_BUNDLES}`;
    dispatch(getAllBundlesByCustomFilter(uri));
  };

  useEffect(() => {
    if (props.togleEvent !== 0 && props.isModal) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [props.togleEvent, props.isModal]);

  const handleInput = (name: any, value: any) => {
    setInputData((prval) => {
      return {
        ...prval,
        [name]: value,
      };
    });
  };

  const handleSwitch = (field, event) => {
    let { checked } = event.target;
    setInputData((preval) => {
      return {
        ...preval,
        [field]: checked === true ? "Y" : "N",
      };
    });
  };
  const handleEditorState = (field, value) => {
    if (value === "<p></p>") {
      value = "";
    }
    setEditorState({ ...editorState, ...inputData, [field]: value });
  };

  const handleBlurEditor = (field) => {
    let newEditorState = { ...editorState };
    var html = newEditorState[`${field}`];
    var div = document.createElement("div");
    div.innerHTML = html;
    let newTrimmedValue = div.innerText.trim();
    newEditorState[field] = newTrimmedValue ? newEditorState[`${field}`] : "";
    setInputData({ ...inputData, ...editorState, ...newEditorState });
  };
  const handleBlur = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    let { name } = e.target;
    handleInput(name, val.trim());
  };

  return (
    <>
      <CssBaseline />
      {formLoader && <FormLoader visible={formLoader} />}
      <Grid container xs={12} sm={8} md={12} className={classes.root} justify="center">
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <div className={classes.disable}>
          <CustomSwitch
            field="disabled"
            handleStateChange={handleSwitch}
            checked={inputData.disabled === "Y"}
            label="Disabled"
          />
          </div>
          <CustomInput
            variant="outlined"
            className={classes.input}
            fullWidth
            label="Bundle Name"
            name="bundleName"
            id="bundleName"
            value={inputData.bundleName}
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            error={formik.errors.bundleName}
            helperText={formik.errors.bundleName}
            autoFocus
          />

          <CustomInput
            variant="outlined"
            className={classes.input}
            fullWidth
            label="Bundle Size"
            name="maxBundleSize"
            id="maxBundleSize"
            value={inputData.maxBundleSize}
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            error={formik.errors.maxBundleSize}
            helperText={formik.errors.maxBundleSize}
            autoFocus
          />
          <CustomInput
            variant="outlined"
            className={classes.input}
            fullWidth
            label="Actual Price"
            name="actualPrice"
            id="actualPrice"
            value={inputData.actualPrice}
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            error={formik.errors.actualPrice}
            helperText={formik.errors.actualPrice}
            autoFocus
          />
          <CustomInput
            variant="outlined"
            className={classes.input}
            fullWidth
            label="Bundle Price"
            name="bundlePrice"
            id="bundlePrice"
            value={inputData.bundlePrice}
            handleState={(field: any, value: any) => {
              handleInput(field, value);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            error={formik.errors.bundlePrice}
            helperText={formik.errors.bundlePrice}
            autoFocus
          />

          <>
            <EditorInput
              title="Enter All Details *"
              field="details"
              value={inputData.details}
              onBlur={() => handleBlurEditor("details")}
              errorFound={formik.errors.details}
              handleDataChange={handleEditorState}
            />
            {formik.errors.details ? <div className={classes.textDanger}>{formik.errors.details}</div> : null}
            <EditorInput
              title="What you learn *"
              field="whatYouLearnFromBundle"
              value={inputData.whatYouLearnFromBundle}
              onBlur={() => handleBlurEditor("whatYouLearnFromBundle")}
              errorFound={formik.errors.whatYouLearnFromBundle}
              handleDataChange={handleEditorState}
            />
            {formik.errors.whatYouLearnFromBundle ? <div className={classes.textDanger}>{formik.errors.whatYouLearnFromBundle}</div> : null}
          </>
          {id ? (
            <Grid>
              <BtnSection>
                <SubmitBtn disabled={formLoader} disabledColor={formLoader} onClick={formik.handleSubmit}>
                  Update
                </SubmitBtn>
              </BtnSection>
            </Grid>
          ) : null}
        </form>
      </Grid>
    </>
  );
};
export default BundleForm;
