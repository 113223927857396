import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLabel from "../../../Components/Label/CustomLabel";
import FormLoader from "../../../Components/Loader/formLoader";
import { apiEndPoint } from "../../../constant/ApiEndPoint";
import { getCourseSectionArtifact, getCourseSectionArtifactByParent, getCourseSectionArtifactByParentPdf, getCourseSectionArtifacts } from "../../../redux/action/courseAction";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

interface Props {
  tdcartifactId?: any;
  courseReducer?: any;
  loaderReducer?: any;
  courseSectionId?: any;
}

const PdfPreview: React.FC<Props> = ({ tdcartifactId, courseSectionId }) => {
  const dispatch = useDispatch();
  const { artifactFile, artifactFiles, artifactPdfs } = useSelector((state: Props) => state.courseReducer);
  const { ArtifactLoader } = useSelector((state: Props) => state.loaderReducer);

  useEffect(() => {
    if (tdcartifactId) {
      let uri = `${apiEndPoint.GET_COURSE_SECTION_ARTIFACT}=${tdcartifactId}`;
      dispatch(getCourseSectionArtifact(uri));
    }
  }, [tdcartifactId, dispatch]);

  useEffect(() => {
    let uri = `${apiEndPoint.GET_COURSE_SECTION_ARTIFACTS}=${courseSectionId}`;
    dispatch(getCourseSectionArtifactByParentPdf(uri));
  }, [courseSectionId, dispatch]);

  console.log(artifactPdfs, "artifactPdfs");
let langArray = ["English", "Hindi", "Marathi"];
  if (artifactPdfs && artifactPdfs.length > 0) {
    return (
      <>
        {/* <iframe title={artifactFile} src={`https://docs.google.com/viewer?url=${encodeURIComponent(artifactFile)}&embedded=true`} height="300" width="100%" style={{ marginTop: 3 }} /> */}
        <div style={{ display: "flex" }}>
          {artifactPdfs.map((item, i) => (
            <iframe title={item.name} src={`https://docs.google.com/viewer?url=${encodeURIComponent(item.path)}&embedded=true`} key={i} height="300" width="100%" style={{ marginTop: 3 }} />
          ))}
        </div>
      </>
    );
  }

  return (
    <FormLoader visible={ArtifactLoader} />
  );
};

export default PdfPreview;
